<template>
    <Line :chart-data="chartData" :chart-options="chartOptions" />
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Line } from 'vue-chartjs'
/* eslint-disable vue/no-unused-components */
  export default defineComponent({
    name: 'LineChart',
    components: {
      Line,
    },
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      chartOptions: {
        type: Object,
        required: true,
      },
    },
  })
  </script>
  