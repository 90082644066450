<template>
  <div class="stats-overview grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
    <div class="stat-card">
      <h3 class="stat-title dark:text-gray-300">Account Value</h3>
      <!-- Use formatCurrency method -->
      <p class="stat-value">{{ formatCurrency(accountBalance) }}</p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title dark:text-gray-300">Number of Trades</h3>
      <p class="stat-value">{{ numberOfTrades }}</p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title dark:text-gray-300">Volume Traded</h3>
      <!-- Use formatCurrency method -->
      <p class="stat-value">{{ formatCurrency(totalVolumeTraded) }}</p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title dark:text-gray-300">Avg Holding Time</h3>
      <p class="stat-value">{{ averageHoldingTime }}</p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title dark:text-gray-300">Balance Change</h3>
      <p
        class="stat-value"
        :class="balanceChange > 0 ? 'text-green-500' : 'text-red-500'"
      >
        {{ balanceChange.toFixed(2) }}%
      </p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title">Win Rate</h3>
      <p
        class="stat-value"
        :class="winRate >= 50 ? 'text-green-500' : 'text-red-500'"
      >
        {{ winRate.toFixed(2) }}%
      </p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title">Risk to Reward</h3>
      <p
        class="stat-value"
        :class="riskRewardRatio > 1 ? 'text-green-500' : 'text-red-500'"
      >
        {{ riskRewardRatioDisplay }}
      </p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title">Unrealized Profit</h3>
      <p
        class="stat-value"
        :class="unrealizedProfit > 0 ? 'text-green-500' : 'text-red-500'"
      >
        {{ formatCurrency(unrealizedProfit) }}
      </p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title">Realized Profit</h3>
      <p
        class="stat-value"
        :class="realizedProfit > 0 ? 'text-green-500' : 'text-red-500'"
      >
        {{ formatCurrency(realizedProfit) }}
      </p>
    </div>
    <div class="stat-card">
      <h3 class="stat-title">Absolute Change</h3>
      <p
        class="stat-value"
        :class="absoluteChange > 0 ? 'text-green-500' : 'text-red-500'"
      >
        {{ formatCurrency(absoluteChange) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsOverview',
  props: {
    trades: {
      type: Array,
      required: true,
    },
    chartData: {
      type: Array,
      required: true,
    },
    positions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    numberOfTrades() {
      return this.trades.length;
    },
    winningTrades() {
      return this.trades.filter(trade => trade.profit > 0);
    },
    losingTrades() {
      return this.trades.filter(trade => trade.profit <= 0);
    },
    winRate() {
      if (this.numberOfTrades === 0) return 0;
      return (this.winningTrades.length / this.numberOfTrades) * 100;
    },
    realizedProfit() {
      return this.trades.reduce((acc, trade) => acc + trade.profit, 0);
    },
    unrealizedProfit() {
      return this.positions.reduce((acc, position) => acc + position.profit, 0);
    },
    totalVolumeTraded() {
      return this.trades.reduce(
        (acc, trade) => acc + trade.entryPrice * trade.entryQuantity + trade.exitPrice * trade.exitQuantity,
        0
      );
    },
    averageHoldingTime() {
      if (this.numberOfTrades === 0) return 'N/A';
      const totalTime = this.trades.reduce((acc, trade) => {
        const entryDate = new Date(trade.entryDate);
        const exitDate = new Date(trade.exitDate);
        return acc + (exitDate - entryDate);
      }, 0);
      const avgTimeMs = totalTime / this.numberOfTrades;
      return this.formatDuration(avgTimeMs);
    },
    balanceChange() {
      if (this.chartData.length < 2) return 0;
      const startBalance = this.chartData[0].balance;
      const endBalance = this.chartData[this.chartData.length - 1].balance;
      return ((endBalance - startBalance) / startBalance) * 100;
    },
    absoluteChange() {
      if (this.chartData.length < 2) return 0;
      const startBalance = this.chartData[0].balance;
      const endBalance = this.chartData[this.chartData.length - 1].balance;
      return endBalance - startBalance;
    },
    accountBalance() {
      if (this.chartData.length < 2) return 0;
      const endBalance = this.chartData[this.chartData.length - 1].balance;
      return endBalance;
    },
    riskRewardRatio() {
      const totalWin = this.winningTrades.reduce(
        (acc, trade) => acc + trade.profit,
        0
      );
      const totalLoss = this.losingTrades.reduce(
        (acc, trade) => acc + trade.profit,
        0
      );
      const averageWin = totalWin / (this.winningTrades.length || 1);
      const averageLoss = totalLoss / (this.losingTrades.length || 1);

      if (averageLoss === 0) return 'N/A';
      return Math.abs(averageWin / averageLoss);
    },
    riskRewardRatioDisplay() {
      const ratio = this.riskRewardRatio;
      return ratio === 'N/A' ? 'N/A' : ratio.toFixed(2);
    },
  },
  methods: {
    formatDuration(ms) {
      const totalSeconds = Math.floor(ms / 1000);
      const days = Math.floor(totalSeconds / (3600 * 24));
      const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);

      let result = '';
      if (days > 0) result += `${days}d `;
      if (hours > 0) result += `${hours}h `;
      if (minutes > 0) result += `${minutes}m `;
      return result.trim() || '0m';
    },
    // New method to format currency values
    formatCurrency(value) {
      if (value === null || value === undefined) return 'N/A';
      if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(2)}M`;
      } else if (value >= 1e3) {
        return `$${(value / 1e3).toFixed(2)}K`;
      } else {
        return `$${value.toFixed(2)}`;
      }
    },
  },
};
</script>

<style scoped>
.stats-overview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}
.stat-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.stat-title {
  font-size: 1rem;
  color: #6b7280; /* Tailwind gray-500 */
  margin-bottom: 8px;
}
.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
