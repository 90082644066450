<template>
  <div :class="['min-h-screen', 'flex', 'flex-col', 'items-center', 'p-4', isDarkMode ? 'dark bg-gray-900' : 'bg-gray-100']">
    
    <div class="w-full max-w-4xl flex justify-end mb-4 hidden">
      <button
        @click="toggleDarkMode"
        class="px-4 py-2 rounded focus:outline-none"
        :class="isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'"
      >
        {{ isDarkMode ? 'Light Mode' : 'Dark Mode' }}
      </button>
    </div>

    <h1 class="text-3xl font-bold mb-6 dark:text-white">Spot Trading Stats</h1>

    <!-- Button group for selecting days -->
    <div class="button-group mb-4">
      <button
        v-for="option in [1, 7, 28, 90, 180, 365]"
        style="margin-bottom:10px"
        :key="option"
        :class="[
          'px-4 py-2 mr-2 rounded',
          selectedDays === option ? 'bg-blue-500 text-white' : 'bg-gray-200',
        ]"
        @click="selectDays(option)"
      >
        {{ option }} Day{{ option > 1 ? 's' : '' }}
      </button>
    </div>

    <!-- Include the StatsOverview component -->
    <div class="w-full max-w-4xl">
      <stats-overview
        v-if="statsReady"
        :trades="trades"
        :chart-data="chartData"
        :positions="positions"
      />
      <p v-else>Loading statistics...</p>
    </div>

    <div class="w-full max-w-4xl bg-white shadow rounded-lg p-6 mb-6">
      <!-- Display the chart when data is ready -->
      <line-chart
        v-if="chartReady"
        :chart-data="formattedChartData"
        :chart-options="chartOptions"
      />
      <!-- Show a loading message while data is being fetched -->
      <p v-else>Loading data...</p>
    </div>

    <!-- PositionTable and TradeTable in a flex container -->
    <div class="w-full max-w-4xl flex flex-col justify-between">
      <div class="bg-white shadow rounded-lg p-6 mb-6 flex-1">
        <h2 class="text-xl font-bold mb-4">Current Positions</h2>
        <!-- Pass positions data as a prop -->
        <position-table :positions="positions" />
      </div>
      <div class="bg-white shadow rounded-lg p-6 mb-6 flex-1">
        <h2 class="text-xl font-bold mb-4">Trade History</h2>
        <!-- Pass trades data as a prop -->
        <trade-table :trades="trades" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import LineChart from './components/LineChart.vue'
import TradeTable from './components/TradeTable.vue'
import StatsOverview from './components/StatsOverview.vue'
import PositionTable from './components/PositionTable.vue'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  TimeScale
)

export default defineComponent({
  name: 'App',
  components: {
    LineChart,
    TradeTable,
    StatsOverview,
    PositionTable,
  },
  data() {
    return {
      trades: [],
      chartData: [],
      positions: [],
      chartReady: false,
      statsReady: false,
      selectedDays: 1, // Default selected days
      intervalId: null, // For clearing the interval
      isDarkMode: false, // Dark mode state
    }
  },
  mounted() {
    const storedDays = localStorage.getItem('selectedDays')
    if (storedDays) {
      this.selectedDays = Number(storedDays)
    }

    // Load dark mode preference
    const storedDarkMode = localStorage.getItem('isDarkMode')
    this.isDarkMode = storedDarkMode === 'true' || false
    this.applyDarkModeClass()

    this.fetchChartData()
    this.fetchTradesData()
    this.fetchPositionsData()

    // Set up polling
    this.intervalId = setInterval(() => {
      this.fetchChartData()
      this.fetchTradesData()
      this.fetchPositionsData()
    }, 60000) // Poll every 60 seconds
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId)
  },
  methods: {
    async fetchChartData() {
      this.chartReady = false // Show loading message while fetching
      try {
        const response = await fetch(
          `https://binancebot.ovh:3000/chartdata?days=${this.selectedDays}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        this.chartData = data
        this.chartReady = true
        this.checkStatsReady()
      } catch (error) {
        console.error('Error fetching chart data:', error)
      }
    },
    async fetchTradesData() {
      this.statsReady = false
      try {
        const response = await fetch(
          `https://binancebot.ovh:3000/trades?days=${this.selectedDays}`
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        this.trades = data
        this.statsReady = true
        this.checkStatsReady()
      } catch (error) {
        console.error('Error fetching trades data:', error)
      }
    },
    async fetchPositionsData() {
      try {
        const response = await fetch(`https://binancebot.ovh:3000/positions`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        this.positions = data
      } catch (error) {
        console.error('Error fetching positions data:', error)
      }
    },
    selectDays(option) {
      this.selectedDays = option
      localStorage.setItem('selectedDays', option)
      this.fetchChartData()
      this.fetchTradesData()
      // Optionally fetch positions if they depend on selectedDays
      // this.fetchPositionsData()
    },
    checkStatsReady() {
      if (this.chartReady && this.statsReady) {
        // Both data sets are ready
      }
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode
      localStorage.setItem('isDarkMode', this.isDarkMode)
      this.applyDarkModeClass()
    },

    applyDarkModeClass() {
      const htmlElement = document.documentElement
      if (this.isDarkMode) {
        htmlElement.classList.add('dark');
        console.log('dark');
      } else {
        htmlElement.classList.remove('dark');
        console.log('white');
      }
    },
  },
  computed: {
    formattedChartData() {
      if (!this.chartReady) {
        return {}
      }
      // Use the data from this.chartData
      const filteredData = this.chartData
        .filter((entry) => entry.balance !== null && entry.date !== null)
        .sort((a, b) => a.date - b.date)

      return {
        datasets: [
          {
            label: 'Total balance in USDT',
            data: filteredData.map((entry) => ({
              x: new Date(entry.date), // Convert timestamp to Date object
              y: entry.balance,
            })),
            borderColor: '#3b82f6',
            backgroundColor: '#93c5fd',
            fill: false,
            tension: 0.1,
          },
        ],
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true, // Display legend for datasets
          },
          tooltip: {
            enabled: true, // Enable tooltips
          },
        },
        scales: {
          x: {
            type: 'time', // Use time scale for x-axis
            time: {
              unit: this.selectedDays === 1 ? 'minute' : 'day', // Adjusted units
              tooltipFormat: 'MMM d, h:mm a', // Tooltip format for time
            },
            ticks: {
              display: true, // Show x-axis labels
            },
            grid: {
              drawTicks: false, // Remove tick marks along the x-axis
              drawBorder: false, // Remove the x-axis border
            },
          },
          y: {
            title: {
              display: false,
              text: 'Total in USDT', // Add a title to the y-axis
            },
            ticks: {
              beginAtZero: true, // Ensure the y-axis starts at zero
            },
            grid: {
              drawTicks: false, // Show ticks on the y-axis
              drawBorder: false, // Keep the y-axis border
            },
          },
        },
      }
    },
  },
})
</script>

<style scoped>
.button-group button {
  cursor: pointer;
}
</style>
