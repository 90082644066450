<template>
  <div>
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <!-- Removed Trade ID column -->
            <th class="py-2 px-4 border-b">Market</th>
            <th class="py-2 px-4 border-b">Entry Price</th>
            <th class="py-2 px-4 border-b">Exit Price</th>
            <th class="py-2 px-4 border-b">Profit</th>
            <th class="py-2 px-4 border-b">Profit %</th>
            <th class="py-2 px-4 border-b">Exit Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="trade in paginatedTrades" :key="trade.tradeId">
            <!-- Market Column with Clickable Link -->
            <td class="py-2 px-4 border-b">
              <a
                :href="getMarketLink(trade.market)"
                target="_blank"
                rel="noopener noreferrer"
                class="text-blue-500 hover:underline"
              >
                {{ trade.market }}
              </a>
            </td>
            <td class="py-2 px-4 border-b">{{ formatNumber(trade.entryPrice, 3) }}</td>
            <td class="py-2 px-4 border-b">{{ formatNumber(trade.exitPrice, 3) }}</td>
            <td
              class="py-2 px-4 border-b"
              :class="trade.profit >= 0 ? 'text-green-500' : 'text-red-500'"
            >
              {{ formatNumber(trade.profit, 2) }}
            </td>
            <td
              class="py-2 px-4 border-b"
              :class="trade.profitPercentage >= 0 ? 'text-green-500' : 'text-red-500'"
            >
              {{ formatPercentage(trade.profitPercentage) }}
            </td>
            <td class="py-2 px-4 border-b">{{ formatDate(trade.exitDate) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Controls -->
    <div class="flex justify-center mt-4">
      <button
        class="px-4 py-2 mx-1 bg-gray-200 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="currentPage === 1"
        @click="prevPage"
      >
        Previous
      </button>
      <button
        v-for="page in totalPages"
        :key="page"
        class="px-4 py-2 mx-1 rounded"
        :class="currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200'"
        @click="goToPage(page)"
      >
        {{ page }}
      </button>
      <button
        class="px-4 py-2 mx-1 bg-gray-200 rounded disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="currentPage === totalPages"
        @click="nextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TradeTable',
  props: {
    trades: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      tradesPerPage: 25, // Number of trades per page
    }
  },
  methods: {
    formatDate(timestamp) {
      if (!timestamp) return 'N/A'
      const date = new Date(timestamp)
      return date.toLocaleDateString() // Only date, no time
    },
    formatNumber(value, precision) {
      if (value === null || value === undefined) return 'N/A'
      return parseFloat(value).toFixed(precision) // Round to specified decimal places
    },
    formatPercentage(value) {
      if (value === null || value === undefined) return 'N/A'
      return `${parseFloat(value).toFixed(2)}%` // Round to 2 decimal places
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    goToPage(page) {
      this.currentPage = page
    },
    getMarketLink(market) {
      if (!market || typeof market !== 'string') return '#'
      
      // List of common quote assets on Binance
      const quoteAssets = ['USDT', 'BUSD', 'BTC', 'ETH', 'BNB', 'EUR', 'GBP', 'TRY', 'RUB', 'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'HKD', 'INR', 'JPY', 'KRW', 'MXN', 'NZD', 'PLN', 'SGD', 'UAH', 'ZAR']
      
      let quote = 'USDT' // Default quote if none match
      for (let qa of quoteAssets) {
        if (market.endsWith(qa)) {
          quote = qa
          break
        }
      }
      
      const base = market.slice(0, market.length - quote.length)
      
      // If base is empty or undefined, return a placeholder link
      if (!base) return '#'
      
      return `https://www.binance.com/fr/trade/${base}_${quote}?type=spot`
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.trades.length / this.tradesPerPage)
    },
    paginatedTrades() {
      const start = (this.currentPage - 1) * this.tradesPerPage
      const end = start + this.tradesPerPage
      return this.trades.slice(start, end)
    },
  },
})
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}
th {
  background-color: #f3f4f6;
}
@media (max-width: 768px) {
  /* For mobile devices */
  th,
  td {
    padding: 8px;
    font-size: 14px;
  }
  th {
    font-size: 14px;
  }
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
